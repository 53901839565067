import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import set from 'lodash/fp/set'
import axios from 'axios'
import { getCookie, setCookie } from '../../../static/js/mixins'
import URL from 'url-parse'
import { isURL } from 'validator'

import { A, Icon, Selector, Text, Modal, ModalContent, SpotIllustration } from '@rebrandly/styleguide'
import ReCAPTCHA from 'react-google-recaptcha'

import TwitterButton from './generators/TwitterButton'
import FacebookButton from './generators/FacebookButton'
import PinterestButton from './generators/PinterestButton'
import LinkedinButton from './generators/LinkedinButton'
import WhatsappButton from './generators/WhatsappButton'
import EmailButton from './generators/EmailButton'
import TelephoneButton from './generators/TelephoneButton'
import SMSButton from './generators/SMSButton'
import UrlButton from './generators/UrlButton'
import QrCodeConfiguration from './QrCodeConfiguration'
import NotificationToast from '../NotificationToast'
import ShortenedLink from '../ShortenedLink'
import SignUpButton from '../SignUpButton'

import {decryptWithAES, DEFAULT_QR_CODE_SETTINGS, encryptWithAES} from './utils/utils'
import {copyToClipboard, encryptWithSHA256, isTestEnv, LIMIT_LINKS_DISPLAY, validateCookieLink} from '../../utils/common'
import blacklist from '../../utils/blacklist'

import './QrCodeGenerator.css'

const buttonsInfo = [
  {
    value: 0,
    label: 'URL',
    icon: {
      name: 'ic-link',
      color: '#1da1f2'
    },
    Component: UrlButton
  },
  {
    value: 4,
    label: 'x',
    icon: {
      name: 'ic-social-twitter-nofill',
      color: '#1da1f2'
    },
    Component: TwitterButton
  },
  {
    value: 1,
    label: 'facebook',
    icon: {
      name: 'ic-social-facebook-nofill',
      color: '#3b5998'
    },
    Component: FacebookButton
  },
  {
    value: 3,
    label: 'pinterest',
    icon: {
      name: 'ic-social-pinterest-nofill',
      color: '#BD081C'
    },
    Component: PinterestButton
  },
  {
    value: 2,
    label: 'linkedin',
    icon: {
      name: 'ic-social-linkedin-nofill',
      color: '#0077B5'
    },
    Component: LinkedinButton
  },
  {
    value: 5,
    label: 'whatsapp',
    icon: {
      name: 'ic-social-whatsapp-nofill',
      color: '#00E676'
    },
    Component: WhatsappButton
  },
  {
    value: 6,
    label: 'email',
    icon: {
      name: 'ic-mail'
    },
    Component: EmailButton
  },
  {
    value: 7,
    label: 'telephone',
    icon: {
      name: 'ic-phone-call'
    },
    Component: TelephoneButton
  },
  {
    value: 8,
    label: 'SMS',
    icon: {
      name: 'ic-device-smartphone'
    },
    Component: SMSButton
  }
]

const QR_CODE_COOKIE = encryptWithSHA256('qr_codes_created')
const QR_DATA_COOKIE = encryptWithSHA256('qr-data')
const FREE_LINKS_LIMIT = 5

const QrCodeGenerator = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const [settings, setSettings] = useState(DEFAULT_QR_CODE_SETTINGS)
  const [destination, setDestination] = useState({url: null, valid: false})
  const [mergedLinks, setMergedLinks] = useState(() => {
    const cache = JSON.parse(getCookie(QR_DATA_COOKIE))
    if (!(cache && cache.entries && cache.entries.length > 0)) {
      return { entries: [] }
    }
    let entries = { entries: cache.entries.filter(k => validateCookieLink(k)) }
    setCookie(QR_DATA_COOKIE, JSON.stringify(entries), 30)

    return entries
  })

  const [showUpsell, setShowUpsell] = useState(null)
  const [reset, setReset] = useState(false)
  const [notification, setNotification] = useState({})

  const recaptchaRef = useRef()
  const endPoint = useMemo(() => isTestEnv() ? 'https://free-url-shortener.test.rb.gy/shorten' : 'https://free-url-shortener.rb.gy/shorten', [])

  const handleChangeSettings = (key, value) => {
    setSettings(s => set(key, value, s))
  }

  const removeNotification = useCallback(() => setNotification({}), [])

  const handleDestination = useCallback((url, valid) => {
    setDestination((current) => {
      if (!url) return {url: null, valid: false}
      let decodedUrl = decodeURIComponent(url)

      let urlWithProtocol = decodedUrl?.startsWith('http') ? decodedUrl : `https://${decodedUrl}`
      return {url: urlWithProtocol, valid}
    })
  }, [])

  const handleHideLink = useCallback((index) => {
    const _entries = mergedLinks.entries.slice(0)
    _entries.splice(index, 1)
    const merge = { entries: _entries }
    setMergedLinks(merge)
    setCookie(QR_DATA_COOKIE, JSON.stringify(merge), 30)
  }, [mergedLinks])

  const handleGenerate = useCallback(async (value) => {
    // await call the /shorten endpoint to create the rb.gy shortUrl - crete the qr-code link with the new shortUrl and the qr-code settings
    const headers = {
      'g-recaptcha-response': value,
      'content-type': 'application/x-www-form-urlencoded'
    }

    const jsonData = {
      destination: destination.url
    }

    try {
      const { data } = await axios({
        method: 'post',
        url: endPoint,
        headers,
        data: jsonData
      })

      const shiftedLinks = { entries: [...mergedLinks.entries.slice(0, LIMIT_LINKS_DISPLAY - 1)] }

      shiftedLinks.entries.unshift({
        destination: destination.url,
        shortUrl: data.shortUrl,
        settings,
        id: data.id
      })
      setCookie(QR_DATA_COOKIE, JSON.stringify(shiftedLinks), 30)
      setCookie(QR_CODE_COOKIE, ((parseInt(getCookie(QR_CODE_COOKIE)) + 1) || mergedLinks.entries.length + 1).toString())

      await copyToClipboard(data.shortUrl)

      setNotification({ type: 'info', message: 'QR Code created' })
      setDestination({ url: null, valid: false })
      setMergedLinks(shiftedLinks)
      setReset(true)

      recaptchaRef.current.reset()

      return data
    } catch (e) {
      console.log('QRError:', e)
      throw e
    }
  }, [settings, destination.url, endPoint, mergedLinks])

  const handleSubmit = useCallback(async (file, payload) => {
    if(parseInt(getCookie(QR_CODE_COOKIE)) >= FREE_LINKS_LIMIT) {
      setShowUpsell('qrcode')
      return Promise.resolve()
    }

    let { hostname } = new URL(destination.url, {}, true)
    let notification

    if (!isURL(destination.url, { allow_underscores: true }) || blacklist.some((_host) => _host === hostname)) {
      notification = {message: 'Invalid URL', type: 'error' }
    }
    if (mergedLinks.entries.some(entry => entry.destination === destination.url)) {
      notification = { message: 'Already shortened', type: 'error' }
    }
    if (notification) {
      setNotification(notification)
      return false
    }

    const token = await recaptchaRef.current.executeAsync()

    return handleGenerate(token)
  }, [mergedLinks, destination.url, handleGenerate])

  const renderNotification = useCallback(() => {
    if (notification.type) {
      return (
        <NotificationToast {...notification} onDelete={removeNotification} />
      )
    }
  }, [notification])

  const selectedSocial = useMemo(() => buttonsInfo.find(o => o.value === currentTab), [currentTab])
  const upsellProps = useMemo(() => {
    switch (showUpsell) {
      case 'qrcode':
        return {
          illustration: 'ill-spot-qr-codes',
          title: 'Need more QR codes?',
          message: <span><span className='main-message'><A size='large' target='_blank' href='https://www.rebrandly.com'>Rebrandly</A> is the right solution for you!<br />
            Sign up for free now and unlock many more features.<br />
            With our <b>free plan</b> you'll get access up to <b>250 QR codes,</b> one for each branded link generated.</span>
            <br /><br />

            <span className='font-semibold m-t-24'>Your shortened links will automatically be imported into your new account</span>
          </span>
        }
      case 'logo':
        return {
          illustration: 'ill-spot-upgrade',
          title: 'Want to use your logo?',
          message: <span>Sign in or sign up for Rebrandly&apos;s Lite plan to start using your logo when creating custom QR codes. Keep your brand everywhere you are.
            <br /><br />

            <span className='font-semibold m-t-24'>Your shortened links will automatically be imported into your new account</span>
          </span>
        }
      default:
        return {}
    }
  }, [showUpsell])

  return (
    <div className='QrCodeGenerator'>
      {renderNotification()}
      <div className='text-center'>
        <h1 className='white-text text-center'>Free QR code generator</h1>
        <Text size='x-small' className='Text--note'>
          The QR code generator is a free tool to shorten URLs powered by <A href='https://rebrandly.com' target='_blank'>Rebrandly</A>. Create, share and download your branded QR code in seconds.
        </Text>
      </div>

      <div className='container hero m-t-36'>
        <div style={{ maxWidth: '100%', width: '100%' }}>
          <div className='row'>
            <div className='col-xs-12 col-sm-2'>
              <ul className='SocialButtons display-none display-sm-block'>
                {buttonsInfo.map(button => {
                  const { value, label, icon: { name } } = button
                  return (
                    <li className={`SocialButtons__tab ${value === currentTab && 'SocialButtons__tab--selected'}`} key={value} onClick={() => setCurrentTab(value)}>
                      <div className='flex middle-xs'>
                        <Icon
                          className='m-r-8'
                          name={name}
                          size='sm'
                          color='var(--color-white)'
                        />
                        <Text>
                          {label}
                        </Text>
                      </div>
                    </li>
                  )
                })}
              </ul>
              <Selector
                className='SocialSelector display-sm-none m-b-24'
                options={buttonsInfo}
                value={{
                  ...selectedSocial,
                  label: (
                    <div className='flex middle-xs'>
                      <Icon
                        className='m-r-8'
                        name={selectedSocial.icon.name}
                        size='xsmall'
                        color='var(--color-white)'
                      />
                      <Text>
                        {selectedSocial.label}
                      </Text>
                    </div>
                  )
                }}
                renderOption={({ label, icon: { name } }) => (
                  <div className='flex middle-xs'>
                    <Icon
                      className='m-r-8'
                      name={name}
                      size='xsmall'
                      color='var(--color-white)'
                    />
                    <Text>
                      {label}
                    </Text>
                  </div>
                )}
                onChange={o => setCurrentTab(o.value)}
              />
            </div>

            <div className='col-xs-12 col-sm-5'>
              {buttonsInfo.map(button => {
                const { value, Component } = button
                return currentTab === value && (
                  <div key={value}>
                    <Component
                      onGenerateClick={handleDestination}
                      forceReset={reset}
                      onClose={() => setReset(false)}
                      debounceGenerate
                    />
                  </div>
                )
              })}
            </div>

            <div className='col-xs-12 col-sm-5'>
              <div>
                <div className='QrCodeSettings'>
                  <QrCodeConfiguration
                    settings={settings}
                    onSubmit={handleSubmit}
                    onChange={handleChangeSettings}
                    onUploadLogo={() => setShowUpsell('logo')}
                    canSubmit={!!destination.url && destination.valid}
                  />
                </div>

                <Text className='Text--note text-center m-t-8' size='x-small'>
                  © ‘QR Code’ is a registered trademark of DENSO WAVE INCORPORATED
                </Text>
                <Text className='Text--note text-center' size='x-small'>
                  By clicking Generate, you agree to Rebrandly&apos;s <A size='sm' target='_blank' href='https://rebrandly.com/terms-conditions'>Terms of Use</A>, <A size='sm' target='_blank' href='https://rebrandly.com/privacy-policy'>Privacy Policy</A> and <A size='sm' target='_blank' href='https://rebrandly.com/cookies'>Cookie Policy</A>
                </Text>
              </div>
            </div>
          </div>

          <div className='shortened-links-list m-t-36'>
            {mergedLinks.entries.slice(0, LIMIT_LINKS_DISPLAY)
              .map(({ destination, shortUrl, settings }, index) => {
                return (
                  <ShortenedLink
                    destination={destination}
                    shortUrl={shortUrl}
                    settings={settings}
                    onHide={handleHideLink}
                    index={index}
                    qrCodeIcon='ic-download'
                  />
                )
              })
            }
          </div>
        </div>
      </div>

      <Modal size='md' isOpen={!!showUpsell} showClose onClose={() => setShowUpsell(null)}>
        <ModalContent>
          <div>
            <div className='AlertPanel AlertPanel--md'>

              <div className='AlertPanel__icon'>
                <SpotIllustration name={upsellProps?.illustration} color='var(--color-blue-50)' />
              </div>

              <div className='AlertPanel__title'>
                {upsellProps?.title}
              </div>

              <div className='AlertPanel__message'>
                <Text>
                  {upsellProps?.message}
                </Text>
              </div>

              <div className='AlertPanel__cta'>
                <SignUpButton size='lg' theme='primary' className='m-t-16' label='Sign up for free' linksIds={mergedLinks.entries.map(k => k.id)} />
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>

      <div className='g-recaptcha'>
        <ReCAPTCHA
          size='invisible'
          badge='bottomleft'
          ref={recaptchaRef}
          sitekey='6LfdFbgUAAAAAPela23tB6kuh06cslnUked_PTWo'
        />
      </div>
    </div>
  )
}

export default QrCodeGenerator

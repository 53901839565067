import React, { Fragment, PureComponent } from 'react'
import { node, string } from 'prop-types'
import { Icon, Input, Label, Text, Alert, Tooltip, Illustration, Button } from '@rebrandly/styleguide'
import {copyToClipboard} from '../../utils/common'

class ResultView extends PureComponent {
  state = { copied: false }

  copyToClipboard (shortUrl, e) {
    e.preventDefault()
    copyToClipboard(shortUrl)
      .then(() => {
        this.setState({ copied: true })
      })
      .catch((e) => {
        this.setState({ copied: false })
      })
  }

  render () {
    let referral
    const { title, tooltipOverlay, url, text } = this.props
    const shareUrl = decodeURIComponent(url)

    return (
      <Fragment>
        <div className='m-b-24'>
          <div className='flex'>
            <Text>{title}</Text>
            <Tooltip overlay={tooltipOverlay} placement='top'>
              <Icon className='m-l-4 m-t-4' name='ic-help' size='tiny' color='#c7c9cb' />
            </Tooltip>
          </div>
          {text && <Text className='Text--SubDetail m-t-8' size='x-small'>{text}</Text>}
        </div>

        <div className='InputList__node m-b-12'>
          <Label value='Share URL'>
            <Input
              ref={ref => (referral = ref)}
              className='ShareInput'
              value={shareUrl}
              submit={
                <Button
                  className='copy'
                  label={this.state.copied ? 'Copied' : 'Copy'}
                  theme='primary'
                  data-clipboard-text={shareUrl}
                  onClick={(e) => {
                    this.copyToClipboard(referral, e)
                  }}
                  disabled={this.state.copied}
                />
              }
            />
          </Label>
        </div>

        {this.props.message &&
          <Alert className='m-t-16' bgColor='#eaf6ff' image={<Illustration size='tiny' color='#2c96df' name='ill-upgrade' />} noBorder>
            {this.props.message}
          </Alert>
        }
      </Fragment>
    )
  }
}

ResultView.propTypes = {
  title: string,
  tooltipOverlay: node,
  text: string,
  message: node,
  url: string
}

ResultView.defaultProps = {
}

export default ResultView

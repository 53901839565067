import React, {useEffect, useState, Fragment, useCallback} from 'react'
import { node, func, bool, string, array, object } from 'prop-types'
import URL from 'url-parse'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import matches from 'validator/lib/matches'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {useDebounce} from 'use-lodash-debounce'

import SocialButton from '../SocialButton'

import {buildList, DEBOUNCE_DELAY, isValidPhoneNumbersList} from '../utils/utils'
import config from '../../../config'

const DEFAULT_BATCH_MIDDLEWARE_URL = config.api.middleware.batchHandler.baseUrl

const SMSButton = props => {
  const [message, setMessage] = useState(props.message || '')
  const [toList, setToList] = useState(props.toList || [])

  const debouncedMessage = useDebounce(message, DEBOUNCE_DELAY)
  const debouncedToList = useDebounce(toList, DEBOUNCE_DELAY)

  const validate = useCallback((toList) => {
    return !isEmpty(toList) && isValidPhoneNumbersList(toList)
  }, [])

  const getShareURL = useCallback((toList, message) => {
    const numbersList = buildList(toList)

    if (!props.onGenerateClick) {
      const smsURL = new URL(numbersList, { protocol: 'sms:' })

      return encodeURIComponent(`${smsURL.href}?&body=${encodeURIComponent(message)}`)
    }

    const result = new URL(`${DEFAULT_BATCH_MIDDLEWARE_URL}/sms/compose`)
    const query = {
      'rb-dashboard-composer': 'sms',
      to: btoa(numbersList),
      body: message
    }

    result.set('query', queryString.stringify(query))

    return encodeURIComponent(result.href)
  }, [])

  useEffect(() => {
    setMessage(props.message || '')
    setToList(props.toList || [])
  }, [props.message, props.toList])

  useEffect(() => {
    if (props.debounceGenerate) {
      let url = getShareURL(debouncedToList, debouncedMessage)
      onGenerateClick(url, validate(debouncedToList))
    }
  }, [debouncedMessage, debouncedToList, validate, getShareURL])

  const handleClose = () => {
    setMessage(props.message || '')
    setToList(props.toList || [])
    props.onClose && props.onClose()
  }

  const handleGenerateUrl = url => {
    return new Promise(resolve => resolve(props.onGenerateClick(url)))
      .then(() => handleClose())
  }

  const { children, onChange, skipForm, alertMessage, onGenerateClick, className, onOpen, disabled, onClose, ...rest } = props
  const isValid = validate(toList)

  const formOptions = {
    title: '',
    tooltipOverlay: <span>Users who click your branded link will be<br />able to send the configured message in<br />their pre-defined SMS app</span>,
    emailPickers: [
      {
        id: 1,
        name: 'number',
        value: props.number,
        emails: toList,
        inputLabel: 'Phone number (separate multiple numbers with “;”)',
        placeholder: 'e.g. +44654321',
        validator: (e) => {
          let isValidPhoneNumber = false
          const phoneNumber = parsePhoneNumberFromString(e)

          // Case for SHORT CODE SMS NUMBERS exception
          // https://github.com/catamphetamine/libphonenumber-js#difference-from-googles-libphonenumber
          if (e.length === 5) {
            return matches(e, '^\\+?\\d{5}$')
          }
          if (e.length === 6) {
            return matches(e, '^\\+?\\d{6}$')
          }

          if (phoneNumber) {
            isValidPhoneNumber = phoneNumber.isValid()
          }
          return matches(e, '^\\+?\\d*$') && isValidPhoneNumber
        },
        message: 'Phone number not valid',
        inputOnUpdate: (toList) => setToList(toList),
        disabled: toList.length >= 10,
        visible: true
      }
    ],
    textAreas: [
      {
        id: 1,
        name: 'message',
        value: message,
        label: 'Message',
        placeholder: 'Message text',
        onTyping: (message) => setMessage(message)
      }
    ],
    onClick: () => onGenerateClick(getShareURL(toList, message)),
    primaryButtonProps: {
      disabled: !isValid
    },
    secondaryButtonProps: props.deleteProps && {
      ...props.deleteProps
    },
    onChange
  }

  if (props.deleteProps && props.buttonLabel) {
    formOptions.primaryButtonProps.label = props.buttonLabel
  }

  return (
    <Fragment>
      <SocialButton
        className={className}
        socialName='SMS'
        formOptions={formOptions}
        getShareURL={getShareURL}
        onGenerateClick={onGenerateClick && handleGenerateUrl}
        onClose={handleClose}
        skipForm={skipForm}
        alertMessage={alertMessage}
        onOpen={onOpen}
        disabled={disabled}
        debounceGenerate={props.debounceGenerate}
        {...rest}
      >
        {children}
      </SocialButton>
    </Fragment>
  )
}

SMSButton.propTypes = {
  className: string,
  buttonLabel: string,
  children: node,
  onGenerateClick: func,
  number: string,
  toList: array,
  message: string,
  onChange: func,
  skipForm: bool,
  alertMessage: node,
  onClose: func,
  onOpen: func,
  deleteProps: object,
  disabled: bool,
  debounceGenerate: bool
}

export default SMSButton

import React, { PureComponent, Fragment } from 'react'
import { node, func, bool, string, array, object } from 'prop-types'
import URL from 'url-parse'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import SocialButton from '../SocialButton'

import {buildList, DEBOUNCE_DELAY, isValidEmailsList} from '../utils/utils'
import config from '../../../config'
import debounce from 'lodash/debounce'

const DEFAULT_BATCH_MIDDLEWARE_URL = config.api.middleware.batchHandler.baseUrl

class EmailButton extends PureComponent {
  state = {
    subject: this.props.subject || '',
    message: this.props.message || '',
    toList: this.props.toList || [],
    ccList: this.props.ccList || [],
    bccList: this.props.bccList || [],
    ccVisibility: !!this.props.ccAddress || !isEmpty(this.props.ccList),
    bccVisibility: !!this.props.bccAddress || !isEmpty(this.props.bccList)
  }

  debouncedGenerateClick = debounce(() => {
    let url = this.getShareURL()
    this.props.onGenerateClick(url, this.validate())
  }, DEBOUNCE_DELAY)

  componentDidUpdate (prevProps, prevState) {
    if (this.props.subject !== prevProps.subject) {
      this.setState({ subject: this.props.subject })
    }

    if (this.props.message !== prevProps.message) {
      this.setState({ message: this.props.message })
    }

    if (this.props.ccAddress !== prevProps.ccAddress) {
      this.setState({ ccVisibility: !!this.props.ccAddress || !isEmpty(this.props.ccList) })
    }

    if (this.props.bccAddress !== prevProps.bccAddress) {
      this.setState({ bccVisibility: !!this.props.bccAddress || !isEmpty(this.props.bccList) })
    }

    if (!isEqual(this.props.toList, prevProps.toList)) {
      this.setState({ toList: this.props.toList })
    }

    if (!isEqual(this.props.ccList, prevProps.ccList)) {
      this.setState({ ccList: this.props.ccList, ccVisibility: !!this.props.ccAddress || !isEmpty(this.props.ccList) })
    }

    if (!isEqual(this.props.bccList, prevProps.bccList)) {
      this.setState({ bccList: this.props.bccList, bccVisibility: !!this.props.bccAddress || !isEmpty(this.props.bccList) })
    }

    if (this.props.debounceGenerate && this.state !== prevState) {
      this.debouncedGenerateClick()
    }
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose()

    this.setState({
      subject: this.props.subject || '',
      message: this.props.message || '',
      ccVisibility: !!this.props.ccAddress || !isEmpty(this.props.ccList),
      bccVisibility: !!this.props.bccAddress || !isEmpty(this.props.bccList),
      toList: this.props.toList || [],
      ccList: this.props.ccList || [],
      bccList: this.props.bccList || []
    })
  }

  validate = () => !isEmpty(this.state.toList) && isValidEmailsList(this.state.toList) && isValidEmailsList(this.state.ccList) && isValidEmailsList(this.state.bccList)

  handleGenerateUrl = url => {
    return new Promise(resolve => resolve(this.props.onGenerateClick(url, this.validate())))
      .then(() => this.handleClose())
  }

  getShareURL = () => {
    const toList = buildList(this.state.toList)
    const ccList = buildList(this.state.ccList)
    const bccList = buildList(this.state.bccList)
    const { subject, message } = this.state

    if (!this.props.onGenerateClick) {
      const params = queryString.stringify({cc: ccList, bcc: bccList, subject, body: message})

      const mailtoURL = new URL(toList, { protocol: 'mailto:' })
        .set('query', params)
      return encodeURIComponent(mailtoURL.href)
    }

    const result = new URL(`${DEFAULT_BATCH_MIDDLEWARE_URL}/mail/compose`, true)
    const query = {
      'rb-dashboard-composer': 'mailto',
      to: btoa(toList),
      cc: btoa(ccList),
      bcc: btoa(bccList),
      body: message,
      subject
    }

    Object.keys(query).forEach(param => {
      if (!query[param]) {
        delete query[param]
      }
    })

    result.set('query', query)

    return encodeURIComponent(result.href)
  }

  render () {
    const { children, onChange, skipForm, alertMessage, onGenerateClick, className, onOpen, disabled, deleteProps, buttonLabel, emailAddress, subject, message, ccAddress, bccAddress, debounceGenerate, ...rest } = this.props
    const isValid = this.validate()

    const formOptions = {
      title: '',
      tooltipOverlay: <span>Users who click your branded link will be<br />able to email the configured message in<br />their pre-defined email app</span>,
      emailPickers: [
        {
          id: 1,
          name: 'emailAddress',
          value: emailAddress,
          emails: this.state.toList,
          inputLabel: 'To (separate multiple emails with “;”)',
          placeholder: 'john@email.com',
          inputOnUpdate: (toList) => this.setState({ toList }),
          visible: true
        },
        {
          id: 2,
          name: 'ccAddress',
          value: ccAddress,
          emails: this.state.ccList,
          inputLabel: 'CC (optional)',
          placeholder: 'john@email.com',
          inputOnUpdate: (ccList) => this.setState({ ccList }),
          buttonLabel: 'CC',
          onClick: () => this.setState({ ccVisibility: true }),
          visible: this.state.ccVisibility
        },
        {
          id: 3,
          name: 'bccAddress',
          value: bccAddress,
          emails: this.state.bccList,
          inputLabel: 'BCC (optional)',
          placeholder: 'john@email.com',
          inputOnUpdate: (bccList) => this.setState({ bccList }),
          buttonLabel: 'BCC',
          onClick: () => this.setState({ bccVisibility: true }),
          visible: this.state.bccVisibility
        }
      ],
      inputs: [
        {
          id: 4,
          name: 'subject',
          value: subject,
          inputLabel: 'Subject (optional)',
          placeholder: 'Subject',
          inputOnTyping: (subject) => this.setState({ subject }),
          visible: true
        }
      ],
      textAreas: [
        {
          id: 1,
          name: 'message',
          value: message,
          label: 'Message (optional)',
          placeholder: 'Message text',
          onTyping: (message) => this.setState({ message })
        }
      ],
      primaryButtonProps: {
        disabled: !isValid
      },
      secondaryButtonProps: deleteProps && {
        ...deleteProps
      },
      onChange
    }

    if (deleteProps && buttonLabel) {
      formOptions.primaryButtonProps.label = buttonLabel
    }

    return (
      <Fragment>
        <SocialButton
          className={className}
          socialName='Email'
          formOptions={formOptions}
          getShareURL={this.getShareURL}
          onGenerateClick={onGenerateClick && this.handleGenerateUrl}
          onClose={this.handleClose}
          onOpen={onOpen}
          skipForm={skipForm}
          alertMessage={alertMessage}
          disabled={disabled}
          debounceGenerate={debounceGenerate}
          forceReset={rest.forceReset}
        >
          {children}
        </SocialButton>
      </Fragment>
    )
  }
}

EmailButton.propTypes = {
  className: string,
  buttonLabel: string,
  children: node,
  onGenerateClick: func,
  emailAddress: string,
  toList: array,
  subject: string,
  message: string,
  ccAddress: string,
  ccList: array,
  bccAddress: string,
  bccList: array,
  onChange: func,
  skipForm: bool,
  alertMessage: node,
  onClose: func,
  onOpen: func,
  deleteProps: object,
  disabled: bool,
  debounceGenerate: bool
}

export default EmailButton

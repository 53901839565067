import React, { PureComponent, Fragment } from 'react'
import { node, func, bool, string } from 'prop-types'
import debounce from 'lodash/debounce'

import SocialButton from '../SocialButton'
import {DEBOUNCE_DELAY} from '../utils/utils'

class TwitterButton extends PureComponent {
  state = {
    tweet: this.props.tweet || ''
  }

  debouncedGenerateClick = debounce(() => {
    let url = this.getShareURL()
    this.props.onGenerateClick(url, this.validate())
  }, DEBOUNCE_DELAY)

  componentDidUpdate (prevProps, prevState) {
    if (this.props.tweet !== prevProps.tweet) {
      this.setState({ tweet: this.props.tweet })
    }

    if (this.props.debounceGenerate && this.state !== prevState) {
      this.debouncedGenerateClick()
    }
  }

  validate = () => {
    const remainingCharacters = 280 - this.state.tweet.length
    const isExceeding = remainingCharacters < 0
    return this.state.tweet && !isExceeding
  }

  getShareURL = () => encodeURIComponent(`https://twitter.com/intent/tweet?text=${encodeURIComponent(this.state.tweet)}`)

  handleClose = () => {
    this.props.onClose && this.props.onClose()

    this.setState({ tweet: this.props.tweet || '' })
  }

  handleGenerateUrl = url => {
    return new Promise(resolve => resolve(this.props.onGenerateClick(url)))
      .then(() => this.handleClose())
  }

  render () {
    const { children, onChange, skipForm, alertMessage, onGenerateClick, debounceGenerate, ...rest } = this.props
    const remainingCharacters = 280 - this.state.tweet.length
    const isExceeding = remainingCharacters < 0

    const formOptions = {
      title: '',
      textAreas: [
        {
          id: 1,
          name: 'tweet',
          value: this.state.tweet,
          label: 'X message',
          onTyping: (tweet) => this.setState({ tweet }),
          placeholder: 'Type or paste a tweet',
          charactersMessage: `${remainingCharacters} characters remaining`,
          warning: isExceeding
        }
      ],
      primaryButtonProps: {
        disabled: !this.validate()
      },
      onChange
    }

    return (
      <Fragment>
        <SocialButton
          socialName='X'
          formOptions={formOptions}
          getShareURL={this.getShareURL}
          onGenerateClick={onGenerateClick && this.handleGenerateUrl}
          onClose={this.handleClose}
          skipForm={skipForm}
          alertMessage={alertMessage}
          debounceGenerate={debounceGenerate}
          forceReset={rest.forceReset}
        >
          {children}
        </SocialButton>
      </Fragment>
    )
  }
}

TwitterButton.propTypes = {
  children: node,
  onGenerateClick: func,
  tweet: string,
  onChange: func,
  onClose: func,
  skipForm: bool,
  alertMessage: node,
  debounceGenerate: bool
}

export default TwitterButton

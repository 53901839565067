import React, { PureComponent, Fragment } from 'react'
import { node, func, bool, string } from 'prop-types'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import matches from 'validator/lib/matches'
import debounce from 'lodash/debounce'

import SocialButton from '../SocialButton'
import {DEBOUNCE_DELAY} from '../utils/utils'

class WhatsappButton extends PureComponent {
  state = {
    phoneNumber: this.props.phoneNumber || '',
    message: this.props.message || ''
  }

  debouncedGenerateClick = debounce(() => {
    let url = this.getShareURL()
    this.props.onGenerateClick(url, this.validate())
  }, DEBOUNCE_DELAY)

  componentDidUpdate (prevProps, prevState) {
    if (this.props.phoneNumber !== prevProps.phoneNumber) {
      this.setState({ phoneNumber: this.props.phoneNumber })
    }

    if (this.props.message !== prevProps.message) {
      this.setState({ message: this.props.message })
    }

    if (this.props.debounceGenerate && this.state !== prevState) {
      this.debouncedGenerateClick()
    }
  }

  isValidPhoneNumber = () => {
    let isValidPhoneNumber = false
    const phoneNumber = parsePhoneNumberFromString(this.state.phoneNumber)

    if (phoneNumber) {
      isValidPhoneNumber = phoneNumber.isValid()
    }

    return isValidPhoneNumber
  }

  validate = () => this.state.phoneNumber && this.state.message && this.isValidPhoneNumber()

  getShareURL = () => encodeURIComponent(`https://api.whatsapp.com/send?phone=${this.state.phoneNumber}&text=${encodeURIComponent(this.state.message)}`)

  handleClose = () => {
    this.props.onClose && this.props.onClose()

    this.setState({
      phoneNumber: this.props.phoneNumber || '',
      message: this.props.message || ''
    })
  }

  handleGenerateUrl = url => {
    return new Promise(resolve => resolve(this.props.onGenerateClick(url)))
      .then(() => this.handleClose())
  }

  handleChange = value => {
    if (!value) {
      return this.setState({phoneNumber: value})
    }

    if (matches(value, '^\\+?\\d*$')) {
      const parsedValue = value.replace(/^\+?/, '+')

      return this.setState({phoneNumber: parsedValue})
    }
  }

  render () {
    const { children, onChange, skipForm, alertMessage, onGenerateClick, debounceGenerate, ...rest } = this.props

    let isValidPhoneNumber = this.isValidPhoneNumber()
    let numberError = {}

    if (this.state.phoneNumber && !isValidPhoneNumber) {
      numberError = {
        message: 'Phone number not valid',
        theme: 'warning'
      }
    }

    const formOptions = {
      title: '',
      text: 'Make sure you include the country code',
      inputs: [
        {
          id: 1,
          name: 'phone',
          value: this.state.phoneNumber,
          inputLabel: 'Recipient phone number',
          placeholder: 'e.g. +44654321',
          inputOnTyping: this.handleChange,
          visible: true,
          ...numberError
        }, {
          id: 2,
          name: 'message',
          value: this.state.message,
          inputLabel: 'Content you want to share',
          placeholder: 'I want to share with you this...',
          inputOnTyping: (message) => this.setState({ message }),
          visible: true
        }
      ],
      primaryButtonProps: {
        disabled: !this.validate()
      },
      onChange
    }

    return (
      <Fragment>
        <SocialButton
          socialName='Whatsapp'
          formOptions={formOptions}
          getShareURL={this.getShareURL}
          onGenerateClick={onGenerateClick && this.handleGenerateUrl}
          onClose={this.handleClose}
          skipForm={skipForm}
          alertMessage={alertMessage}
          debounceGenerate={debounceGenerate}
          forceReset={rest.forceReset}
        >
          {children}
        </SocialButton>
      </Fragment>
    )
  }
}

WhatsappButton.propTypes = {
  children: node,
  onGenerateClick: func,
  onClose: func,
  phoneNumber: string,
  message: string,
  onChange: func,
  skipForm: bool,
  alertMessage: node,
  debounceGenerate: bool
}

export default WhatsappButton

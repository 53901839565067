import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { node, func, bool, string } from 'prop-types'
import {useDebounce} from 'use-lodash-debounce'
import isURL from 'validator/lib/isURL'

import SocialButton from '../SocialButton'
import {DEBOUNCE_DELAY} from '../utils/utils'

const UrlButton = ({ url, children, onChange, onClose, alertMessage, onGenerateClick, debounceGenerate, ...rest }) => {
  const [currentUrl, setCurrentUrl] = useState(url || '')
  const debouncedUrl = useDebounce(currentUrl, DEBOUNCE_DELAY)

  const validate = useCallback((url) => isURL(url, { allow_underscores: true }), [])

  useEffect(() => {
    if (url) {
      setCurrentUrl(url)
    }
  }, [url])

  useEffect(() => {
    if (debounceGenerate) {
      let url = encodeURIComponent(debouncedUrl)
      onGenerateClick(url, validate(debouncedUrl))
    }
  }, [debouncedUrl, validate])

  const getShareUrl = useCallback(() => encodeURIComponent(currentUrl), [currentUrl])

  const handleClose = useCallback(() => {
    onClose && onClose()
    setCurrentUrl(url || '' )
  }, [url])

  const handleGenerateUrl = useCallback(url => {
    return new Promise(resolve => resolve(onGenerateClick(url)))
      .then(() => handleClose())
  }, [handleClose])

  const formOptions = useMemo(() => ({
    title: '',
    textAreas: [
      {
        id: 1,
        name: 'tweet',
        value: currentUrl,
        label: 'Website URL',
        onTyping: setCurrentUrl,
        placeholder: 'Paste a URL below to link with your QR code',
      }
    ],
    primaryButtonProps: {
      disabled: !currentUrl
    },
    onChange
  }), [currentUrl])

  return (
    <SocialButton
      socialName='Url'
      formOptions={formOptions}
      getShareURL={getShareUrl}
      onGenerateClick={onGenerateClick && handleGenerateUrl}
      onClose={handleClose}
      alertMessage={alertMessage}
      debounceGenerate={debounceGenerate}
      {...rest}
    >
      {children}
    </SocialButton>
  )
}

UrlButton.propTypes = {
  children: node,
  onGenerateClick: func,
  url: string,
  onChange: func,
  onClose: func,
  alertMessage: node
}

export default UrlButton

import React, { PureComponent } from 'react'
import { node, func, bool, string, object } from 'prop-types'

import GenericForm from './GenericForm'
import ResultView from './ResultView'

class SocialButton extends PureComponent {
  state = {
    showCopyResult: false,
    isLoading: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.forceReset && prevProps.forceReset !== this.props.forceReset) {
      this.props.onClose()
    }
  }

  handleGenerateClick = e => {
    e && e.preventDefault()
    if (this.props.disabled) {
      return
    }
    const shareURL = this.props.getShareURL()

    if (!this.props.onGenerateClick) {
      this.setState({ showCopyResult: true })
    } else {
      this.setState(
        { isLoading: true },
        () => new Promise(resolve => resolve(this.props.onGenerateClick(shareURL)))
      )
    }
  }

  renderForm () {
    let formOptions = {
      title: this.props.socialName + ' share link',
      ...this.props.formOptions,
    }

    if (!this.props.debounceGenerate) {
      formOptions = {
        ...formOptions,
        primaryButtonProps: {
          ...this.props.formOptions.primaryButtonProps,
          onClick: this.handleGenerateClick,
          isLoading: this.state.isLoading
        },
        showButtons: true
      }
    }
    if (!this.props.formOptions.tooltipOverlay) {
      formOptions = {
        ...formOptions,
        tooltipOverlay: <span>Users who click your branded<br />link will be able to share this link in<br />their {this.props.socialName} profile</span>
      }
    }

    return !this.state.showCopyResult
      ? <GenericForm {...formOptions} />
      : <ResultView url={this.props.getShareURL()} message={this.props.alertMessage} {...formOptions} />
  }

  render () {
    return this.renderForm()
  }
}

SocialButton.propTypes = {
  className: string,
  socialName: string,
  formOptions: object,
  getShareURL: func,
  children: node,
  onGenerateClick: func,
  skipForm: bool,
  disabled: bool,
  debounceGenerate: bool,
  forceReset: bool,
  alertMessage: node,
  onClose: func,
  onOpen: func
}

export default SocialButton

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@rebrandly/styleguide'
import ColorPicker from './ColorPicker'

import './ColorInput.css'

const ColorInput = ({onType, disabled, color, onChangeComplete, onChange}) => {
  const handleType = useCallback(
    (value) => {
      let result = value
      const m = value.replace(/\s/g, '').match(/^rgba*\((\d+),(\d+),(\d+),?(\d*)?\)$/i)
      if (m) {
        const end = m[4] ? 5 : 4

        result = '#' + m.slice(1, end).map(n => {
          const hex = (+n).toString(16)
          return hex.length === 1 ? '0' + hex : hex
        }).join('')
      }

      onType(result)
    },
    [onType]
  )

  return (
    <div className='ColorInput flex middle-xs'>
      <Input
        value={color}
        disabled={disabled}
        onChange={handleType}
      />
      <ColorPicker
        color={color}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
        disabled={disabled}
      />
    </div>
  )
}

ColorInput.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onType: PropTypes.func,
  onChange: PropTypes.func,
  onChangeComplete: PropTypes.func
}

export default ColorInput

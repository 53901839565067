import React, { PureComponent, Fragment } from 'react'
import { node, func, bool, string } from 'prop-types'
import isURL from 'validator/lib/isURL'
import debounce from 'lodash/debounce'

import SocialButton from '../SocialButton'
import {DEBOUNCE_DELAY} from '../utils/utils'

class PinterestButton extends PureComponent {
  state = {
    destinationUrl: this.props.destinationUrl || '',
    imageSrc: this.props.imageSrc || '',
    description: this.props.description || ''
  }

  debouncedGenerateClick = debounce(() => {
    let url = this.getShareURL()
    this.props.onGenerateClick(url, this.validate())
  }, DEBOUNCE_DELAY)

  componentDidUpdate (prevProps, prevState) {
    if (this.props.destinationUrl !== prevProps.destinationUrl) {
      this.setState({ destinationUrl: this.props.destinationUrl })
    }

    if (this.props.imageSrc !== prevProps.imageSrc) {
      this.setState({ imageSrc: this.props.imageSrc })
    }

    if (this.props.description !== prevProps.description) {
      this.setState({ description: this.props.description })
    }

    if (this.props.debounceGenerate && this.state !== prevState) {
      this.debouncedGenerateClick()
    }
  }

  validate = () => (this.state.destinationUrl && isURL(this.state.destinationUrl))

  getShareURL = () => `${encodeURIComponent(`https://www.pinterest.pt/pin/create/button/?url=${this.state.imageSrc}&media=${this.state.destinationUrl}&description=${encodeURIComponent(this.state.description)}`)}`

  handleClose = () => {
    this.props.onClose && this.props.onClose()

    this.setState({
      destinationUrl: this.props.destinationUrl || '',
      imageSrc: this.props.imageSrc || '',
      description: this.props.description || ''
    })
  }

  handleGenerateUrl = url => {
    return new Promise(resolve => resolve(this.props.onGenerateClick(url)))
      .then(() => this.handleClose())
  }

  render () {
    const { children, onChange, skipForm, alertMessage, onGenerateClick, debounceGenerate, ...rest } = this.props
    const formOptions = {
      title: '',
      inputs: [
        {
          id: 1,
          name: 'image-url',
          value: this.state.destinationUrl,
          inputLabel: 'Image URL',
          placeholder: 'Link to the image you want to share',
          inputOnTyping: (destinationUrl) => this.setState({ destinationUrl }),
          visible: true
        },
        {
          id: 2,
          name: 'source',
          value: this.state.imageSrc,
          inputLabel: 'Source (optional)',
          placeholder: 'Link to the source',
          inputOnTyping: (imageSrc) => this.setState({ imageSrc }),
          visible: true
        }
      ],
      textAreas: [
        {
          id: 1,
          name: 'description',
          value: this.state.description,
          label: 'Description (optional)',
          placeholder: 'Description of your pin',
          onTyping: (description) => this.setState({ description })
        }
      ],
      primaryButtonProps: {
        disabled: !this.validate()
      },
      onChange
    }

    return (
      <SocialButton
        socialName='Pinterest'
        formOptions={formOptions}
        getShareURL={this.getShareURL}
        onGenerateClick={onGenerateClick && this.handleGenerateUrl}
        onClose={this.handleClose}
        skipForm={skipForm}
        alertMessage={alertMessage}
        debounceGenerate={debounceGenerate}
        forceReset={rest.forceReset}
      >
        {children}
      </SocialButton>
    )
  }
}

PinterestButton.propTypes = {
  children: node,
  onGenerateClick: func,
  onClose: func,
  destinationUrl: string,
  imageSrc: string,
  description: string,
  onChange: func,
  skipForm: bool,
  alertMessage: node,
  debounceGenerate: bool
}

export default PinterestButton

import React, { Fragment } from 'react'
import {array, string, func, number, node, object, bool} from 'prop-types'
import { Label, Text, Input, Tooltip, Icon, Button, Textarea, A, EmailPicker } from '@rebrandly/styleguide'
import isEmpty from 'lodash/isEmpty'

import './GenericForm.css'

const GenericForm = ({
  title,
  tooltipOverlay,
  text,
  inputs,
  textAreas,
  emailPickers,
  textareaRows,
  textareaMaxRows,
  onChange,
  primaryButtonProps,
  secondaryButtonProps,
  showButtons
}) => {
  return (
    <Fragment>
      {title && <div className='flex m-b-24'>
        <Text className='GenericForm__title'>{title}</Text>
        <Tooltip overlay={tooltipOverlay} placement='top'>
          <Icon className='m-l-4 m-t-4' name='ic-help' size='tiny' color='#c7c9cb'/>
        </Tooltip>
      </div>}
      {text && <Text className='GenericForm__text m-t-8 m-b-24' size='x-small'>{text}</Text>}

      <form onChange={onChange}>
        {!isEmpty(emailPickers) && emailPickers.map(input => {
          if (input.visible) {
            return (
              <div key={input.id} className='InputList__node m-b-12'>
                <Label value={input.inputLabel}>
                  <EmailPicker
                    name={input.name}
                    value={input.value}
                    emails={input.emails}
                    placeholder={input.placeholder}
                    onChange={input.inputOnTyping}
                    onUpdate={input.inputOnUpdate}
                    validator={input.validator}
                    disabled={input.disabled}
                    warningMessage={input.message}
                  />
                </Label>
              </div>
            )
          }

          return <A key={input.id} className='m-r-12' onClick={input.onClick}>{input.buttonLabel}</A>
        })}

        {!isEmpty(inputs) && inputs.map(input => {
          if (input.visible) {
            return (
              <div key={input.id} className='InputList__node m-b-12'>
                <Label value={input.inputLabel}>
                  <Input
                    name={input.name}
                    value={input.value}
                    placeholder={input.placeholder}
                    onChange={input.inputOnTyping}
                    theme={input.theme}
                    message={input.message}
                  />
                </Label>
              </div>
            )
          }

          return <A key={input.id} className='m-r-12' onClick={input.onClick}>{input.buttonLabel}</A>
        })}

        {!isEmpty(textAreas) && textAreas.map(textarea => {
          let textareaOptions = {
            name: textarea.name,
            value: textarea.value,
            rows: textarea.rows || textareaRows,
            maxRows: textarea.maxRows || textareaMaxRows,
            placeholder: textarea.placeholder,
            onChange: textarea.onTyping
          }

          if (textarea.warning) {
            textareaOptions = {
              ...textareaOptions,
              theme: 'warning',
              message: textarea.charactersMessage
            }
          }

          return (
            <div key={textarea.id} className='TextAreaList__node'>
              <Label value={textarea.label}>
                <Textarea {...textareaOptions} />
              </Label>
              {textarea.charactersMessage && !textarea.warning &&
                <Text className='Text--SubDetail m-t-4' size='x-small'>
                  {textarea.charactersMessage}
                </Text>
              }
            </div>
          )
        })}

        {showButtons &&
          <div className='Button__list m-t-36'>
            {secondaryButtonProps &&
              <Button
                theme='deleteInverted'
                label='Delete'
                block
                {...secondaryButtonProps}
              />
            }

            <Button
              label='Generate URL'
              type='submit'
              theme='primary'
              block
              {...primaryButtonProps}
            />
          </div>
        }
      </form>
    </Fragment>
  )
}

GenericForm.propTypes = {
  title: string,
  tooltipOverlay: node,
  primaryButtonProps: object,
  secondaryButtonProps: object,
  textareaRows: number,
  textareaMaxRows: number,
  onChange: func,
  text: string,
  emailPickers: array,
  inputs: array,
  textAreas: array,
  showButtons: bool
}

GenericForm.defaultProps = {
  textareaRows: 3,
  textareaMaxRows: 10
}

export default GenericForm

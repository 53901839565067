import React, {useState, useEffect, Fragment, useCallback} from 'react'
import { node, func, bool, string, object } from 'prop-types'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {useDebounce} from 'use-lodash-debounce'
import URL from 'url-parse'
import queryString from 'query-string'
import matches from 'validator/lib/matches'

import SocialButton from '../SocialButton'

import config from '../../../config'
import {DEBOUNCE_DELAY} from '../utils/utils'

const DEFAULT_BATCH_MIDDLEWARE_URL = config.api.middleware.batchHandler.baseUrl

const TelephoneButton = props => {
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber || '')
  const debouncedPhone = useDebounce(phoneNumber, DEBOUNCE_DELAY)

  const validatePhoneNumber = useCallback((number) => {
    let isValidPhoneNumber = false

    const parsedPhone = parsePhoneNumberFromString(number)

    if (parsedPhone) {
      isValidPhoneNumber = parsedPhone.isValid()
    }
    return isValidPhoneNumber
  }, [])

  const validate = useCallback((number) => {
    return (number && validatePhoneNumber(number))
  }, [])

  const getShareURL = useCallback((phoneNumber) => {
    if (!props.onGenerateClick) {
      const telURL = new URL(phoneNumber, { protocol: 'tel:' })
      return encodeURIComponent(telURL.href)
    }

    const result = new URL(`${DEFAULT_BATCH_MIDDLEWARE_URL}/tel/compose`)
    const query = {
      'rb-dashboard-composer': 'tel',
      to: btoa(phoneNumber)
    }

    result.set('query', queryString.stringify(query))

    return encodeURIComponent(result.href)
  }, [])

  useEffect(() => {
    setPhoneNumber(props.phoneNumber || '')
  }, [props.phoneNumber])

  useEffect(() => {
    if (props.debounceGenerate) {
      let url = getShareURL(debouncedPhone)
      onGenerateClick(url, validate(debouncedPhone))
    }
  }, [debouncedPhone, validate, getShareURL])

  const handleClose = () => {
    props.onClose && props.onClose()

    setPhoneNumber(props.phoneNumber || '')
  }

  const handleGenerateUrl = url => {
    return new Promise(resolve => resolve(props.onGenerateClick(url)))
      .then(() => handleClose())
  }

  const handleChange = value => {
    if (!value) {
      return setPhoneNumber(value)
    }

    if (matches(value, '^\\+?\\d*$')) {
      const parsedValue = value.replace(/^\+?/, '+')

      return setPhoneNumber(parsedValue)
    }
  }

  const { children, onChange, skipForm, alertMessage, onGenerateClick, onOpen, disabled, ...rest } = props

  let numberError = {}
  let isValidPhoneNumber = validatePhoneNumber(phoneNumber)

  if (phoneNumber && !isValidPhoneNumber) {
    numberError = {
      message: 'Phone number not valid',
      theme: 'warning'
    }
  }

  const formOptions = {
    title: '',
    text: 'Make sure you include the proper country code',
    inputs: [
      {
        id: 1,
        name: 'phone',
        value: phoneNumber,
        inputLabel: 'Recipient phone number',
        placeholder: 'e.g. +44654321',
        inputOnTyping: handleChange,
        visible: true,
        ...numberError
      }
    ],
    primaryButtonProps: {
      disabled: !validate(phoneNumber)
    },
    secondaryButtonProps: props.deleteProps && {
      ...props.deleteProps
    },
    tooltipOverlay: <span>Users who click your branded link<br />will be able to call the number with<br />their pre-defined phone call app</span>,
    onChange
  }

  if (props.deleteProps && props.buttonLabel) {
    formOptions.primaryButtonProps.label = props.buttonLabel
  }

  return (
    <Fragment>
      <SocialButton
        socialName='Telephone'
        formOptions={formOptions}
        getShareURL={() => getShareURL(phoneNumber)}
        onGenerateClick={onGenerateClick && handleGenerateUrl}
        onClose={handleClose}
        skipForm={skipForm}
        alertMessage={alertMessage}
        onOpen={onOpen}
        disabled={disabled}
        debounceGenerate={props.debounceGenerate}
        {...rest}
      >
        {children}
      </SocialButton>
    </Fragment>
  )
}

TelephoneButton.propTypes = {
  children: node,
  buttonLabel: string,
  onGenerateClick: func,
  onClose: func,
  onOpen: func,
  phoneNumber: string,
  onChange: func,
  skipForm: bool,
  alertMessage: node,
  deleteProps: object,
  disabled: bool,
  debounceGenerate: bool
}

export default TelephoneButton

import React from 'react'
import { func, node, string, bool } from 'prop-types'
import { Dropdown, Trigger, Content, IconButton } from '@rebrandly/styleguide'
import { SketchPicker } from 'react-color'

const ColorPicker = ({ color, onChange, onChangeComplete, trigger, disabled }) => (
  <Dropdown className='SketchPicker__dropdown' disabled={disabled}>
    <Trigger>
      {trigger || <IconButton className='p-0' theme='ghost' icon={{ name: 'ic-color-picker', size: 'xsmall' }} disabled={disabled} />}
    </Trigger>
    <Content position='right'>
      <SketchPicker color={color} onChange={onChange} onChangeComplete={onChangeComplete} />
    </Content>
  </Dropdown>
)

ColorPicker.propTypes = {
  color: string,
  onChange: func,
  onChangeComplete: func,
  trigger: node,
  disabled: bool
}

export default ColorPicker

import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-lodash-debounce'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import omit from 'lodash/omit'
import forIn from 'lodash/forIn'

import {
  Slider,
  Button,
  Icon,
  InputFilesUpload,
  Text,
  Label,
  Tag,
  Loading
} from '@rebrandly/styleguide'
import ColorInput from '../ColorInput'

import { composeQrCodeUrl } from './utils/utils'

import './QrCodeConfiguration.css'

// const SIZE_LIMIT = 2000
// const HEIGHT_LIMIT = 2048
// const WIDTH_LIMIT = 1536
const DEFAULT_QRCODE_SIZE = 128

const BACKGROUND_KEY = 'options.colorLight'
const MODULES_KEY = 'options.colorDark'
const MARGIN_KEY = 'options.margin'
const RATIO_KEY = 'options.logoRatio'
const SIZE_KEY = 'options.size'
const LOGO_KEY = 'logo'

const QrCodeConfiguration = ({settings, domain, onSubmit, canSubmit, onChange, onUploadLogo}) => {
  const [isSendingData, setIsSendingData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [mounted, setMounted] = useState(false)

  const [error] = useState(false)

  const debouncedBackgroundColor = useDebounce(get(settings, BACKGROUND_KEY), 750, {maxWait: 1000})
  const debouncedModuleColor = useDebounce(get(settings, MODULES_KEY), 750, {maxWait: 1000})
  const debouncedRatio = useDebounce(get(settings, RATIO_KEY), 750, {maxWait: 1000})
  const debouncedMargin = useDebounce(get(settings, MARGIN_KEY), 500, {maxWait: 1000})

  const qrCodeURL = useMemo(() => {
    let dummyParam = settings?.logo + Math.random() // @dev this is a hack to prevent img tag from caching the image and not updating the logo after is uploaded
    let debouncedSettings = { options: {
        size: DEFAULT_QRCODE_SIZE,
        margin: debouncedMargin,
        ratio: debouncedRatio,
        colorLight: debouncedBackgroundColor,
        colorDark: debouncedModuleColor
      }
    }

    return `${composeQrCodeUrl(domain?.fullName, debouncedSettings)}&dummy=${dummyParam}`
  }, [debouncedModuleColor, debouncedBackgroundColor, debouncedRatio, debouncedMargin, domain?.fullName, settings?.logo])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    setIsLoading(true)
  }, [settings])

  const buildPayload = () => {
    let payload = pickBy(settings, v => v)

    let keysOfSettingsNotChanged = []
    forIn(payload, (value, key) => {
      if (settings?.options[key] === value) {
        keysOfSettingsNotChanged.push(key)
      }
    })

    return omit(payload, keysOfSettingsNotChanged)
  }

  const handleSubmit = () => {
    setIsSendingData(true)

    const payload = buildPayload()

    onSubmit(null, payload)
      .finally(() => setIsSendingData(false))
  }

  let text = <span>Drag and drop the file, or <span className='UploadFile__text--engage font-semibold'>Select from computer</span> </span>
  let icon = <Icon name='ic-file' color='#c7c9cb' size='sm' />

  const isValid = !error && (!isEmpty(buildPayload()) || file)
  const backgroundColor = get(settings, BACKGROUND_KEY)
  const moduleColor = get(settings, MODULES_KEY)
  const margin = get(settings, MARGIN_KEY)

  const qrCodePreview = (
    <div className='QR__code-container'>
      {mounted && <img src={qrCodeURL}  onLoad={() => setIsLoading(false)} alt='qr-code' />}
      {isLoading && <div className="QR__preview__loading">
        <Loading />
      </div>}
    </div>
  )

  return (
    <div>
      <div className='QR__modal-content'>
        <div className='QR__preview'>
          {qrCodePreview}
        </div>

        <div className='QR__settings m-t-48'>
          <div className='row'>
            <Label className='col-xs-6' value='Background color'>
              <ColorInput
                color={backgroundColor || '#ffffff'}
                onType={value => onChange(BACKGROUND_KEY, value)}
                onChangeComplete={({hex}) => onChange(BACKGROUND_KEY, hex)}
              />
            </Label>

            <Label className='col-xs-6' value='Foreground color'>
              <ColorInput
                color={moduleColor || '#000000'}
                onType={value => onChange(MODULES_KEY, value)}
                onChangeComplete={({hex}) => onChange(MODULES_KEY, hex)}
              />
            </Label>
          </div>

          <div className='m-t-24'>
            <Label value='Margin' tooltip={{overlay: <span>Set the quiet zone, that is a clear area<br />around a symbol where nothing is printed.<br />The margin can make easier scanning a code.<br />
                    The suggested value is 4.</span>}}
            >
              <div className='row middle-xs'>
                <div className='col-xs-2'>
                  <Tag color='var(--color-grey-400)'>
                    <Text size='small' className='Text--Negative'>{margin}</Text>
                  </Tag>
                </div>
                <div className='col-xs'>
                  <Slider
                    min='0'
                    max='10'
                    step='1'
                    value={margin}
                    onChange={value => onChange(MARGIN_KEY, +value)}
                  />
                </div>
              </div>
            </Label>
          </div>

          <div className='QR__settings--logo flex middle-xs m-t-24'>
            {<div className='QR__settings--input-logo'>
              <Label onClick={onUploadLogo}>
                <InputFilesUpload
                  name='qr-code-logo'
                  title={
                    <Text size='x-small' className='Text--SubDetail font-semibold'>
                      Upload a logo
                    </Text>
                  }
                  subtitle={
                    <Text size='x-small' className='Text--SubDetail m-l-4'>
                      (PNG format, Max size 2MB, 1536 x 2048)
                    </Text>
                  }
                  text={text}
                  icon={icon}
                  filesType='image/png'
                  disabled
                />
              </Label>
            </div>}
          </div>
        </div>
      </div>

      <div className='Button__list m-t-24'>
        <Button
          label='Generate'
          theme='primary'
          onClick={handleSubmit}
          isLoading={isSendingData}
          disabled={!canSubmit || !isValid}
          block
        />
      </div>
    </div>
  )
}

QrCodeConfiguration.propTypes = {
  domain: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onUploadLogo: PropTypes.func,
  onDeleteLogo: PropTypes.func,
  settings: PropTypes.object,
  canSubmit: PropTypes.bool
}
QrCodeConfiguration.defaultProps = {
  domain: {
    fullName: 'rb.gy'
  },
  canSubmit: true
}

export default QrCodeConfiguration

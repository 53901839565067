import React, { PureComponent, Fragment } from 'react'
import { node, func, bool, string } from 'prop-types'
import debounce from 'lodash/debounce'
import isURL from 'validator/lib/isURL'

import SocialButton from '../SocialButton'
import {DEBOUNCE_DELAY} from '../utils/utils'

class FacebookButton extends PureComponent {
  state = {
    destinationUrl: this.props.destinationUrl || ''
  }

  debouncedGenerateClick = debounce(() => {
    let url = this.getShareURL()
    this.props.onGenerateClick(url, this.validate())
  }, DEBOUNCE_DELAY)

  componentDidUpdate (prevProps, prevState) {
    if (this.props.destinationUrl !== prevProps.destinationUrl) {
      this.setState({ destinationUrl: this.props.destinationUrl })
    }

    if (this.props.debounceGenerate && this.state !== prevState) {
      this.debouncedGenerateClick()
    }
  }

  handleChange = destinationUrl => this.setState({ destinationUrl })

  validate = () => (this.state.destinationUrl && isURL(this.state.destinationUrl))

  getShareURL = () => `https://www.facebook.com/sharer/sharer.php?u=${this.state.destinationUrl}`

  handleClose = () => {
    this.props.onClose && this.props.onClose()

    this.setState({ destinationUrl: this.props.destinationUrl || '' })
  }

  handleGenerateUrl = url => {
    return new Promise(resolve => resolve(this.props.onGenerateClick(url)))
      .then(() => this.handleClose())
  }

  render () {
    const { onGenerateClick, children, onChange, skipForm, alertMessage, debounceGenerate, ...rest } = this.props
    const formOptions = {
      title: '',
      text: 'Facebook will automatically truncate links that exceed 51 characters (19 domain + 32 slashtag)',
      inputs: [
        {
          id: 1,
          name: 'url',
          value: this.state.destinationUrl,
          inputLabel: 'URL you want to share',
          placeholder: 'Type or paste a link (URL)',
          inputOnTyping: this.handleChange,
          visible: true
        }
      ],
      primaryButtonProps: {
        disabled: !this.validate()
      },
      onChange
    }

    return (
      <Fragment>
        <SocialButton
          socialName='Facebook'
          formOptions={formOptions}
          getShareURL={this.getShareURL}
          onGenerateClick={onGenerateClick && this.handleGenerateUrl}
          onClose={this.handleClose}
          skipForm={skipForm}
          alertMessage={alertMessage}
          debounceGenerate={debounceGenerate}
          forceReset={rest.forceReset}
        >
          {children}
        </SocialButton>
      </Fragment>
    )
  }
}

FacebookButton.propTypes = {
  children: node,
  onGenerateClick: func,
  onClose: func,
  destinationUrl: string,
  onChange: func,
  skipForm: bool,
  alertMessage: node,
  debounceGenerate: bool
}

export default FacebookButton
